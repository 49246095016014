:root {
  --box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

body {
  font-family: 'Roboto', sans-serif;
  margin: 0;
  background-image: url('img/cycling.jpg');
  background-size: cover;
  height: 100vh;
}
/*PRELOADER*/
.loader-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: #171718;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  opacity: 1;
  transition: opacity 4s ease;
}

.loader-container.finish {
  opacity: 0;
  pointer-events: none;
  /*display: none;*/
}

.loader {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #800080;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
  transition: opacity 2s ease;
}

.container {
  max-width: 1100px;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  font-family: 'Roboto', sans-serif;
}

h3 {
  color: white;
  margin-bottom: 0;
  padding: 10px;
}

/* NAV */
.fa-bicycle {
  font-size: 36px;
  color: purple;
}

.navbar-nav > .nav-item {
  margin-right: 100px;
}

.navbar-brand {
  margin-left: 100px;
}

.color-nav {
  background-color: rgba(44, 48, 48, 0.7);
}

.user-name {
  color: purple;
}

.navbar-text > .fa-user {
  color: purple;
}

/* GREETING */
.greeting > h1 {
  font-family: 'Lobster', cursive;
  font-size: 64px;
  color: white;
}

/* FORMS */
.form-container {
  background-color: transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  font-family: 'Roboto', sans-serif;
  margin: 0 auto;
  padding-top: 80px;
}

.form-title > span {
  color: purple;
  font-weight: bold;
}

.modal-form {
  position: fixed;
  top: 18%;
}

form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.form-group {
  padding-bottom: 10px;
  width: 450px;
}

.form-group > span {
  float: right;
  margin-right: 30px;
  margin-top: -35px;
  position: relative;
  z-index: 2;
  color: white;
}

.form-control {
  border: none;
  outline: none;
  padding: 25px;
  border-radius: 25px;
  background-color: rgba(255, 255, 255, 0.2);
  height: 50px;
  color: white;
  font-size: 16px;
}

.form-control:focus {
  border-color: #ff80ff;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.075) inset,
    0px 0px 8px rgba(255, 100, 255, 0.5);
  background-color: rgba(255, 255, 255, 0.2);
  color: white;
  font-size: 16px;
}

.form-control.date {
  display: flex;
  align-items: center;
  padding-bottom: 25px;
}

.badge-cust {
  background-color: rgba(44, 48, 48, 0.75);
}

.span-link {
  font-family: 'Lobster', cursive;
  font-weight: bold;
  font-size: 22px;
  color: purple;
  margin-left: 5px;
  text-decoration: none;
}

.span-link:hover {
  text-decoration: none;
  color: #d3d3d3;
}

/* TABLE */
.table-container {
  width: 60vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  font-family: 'Roboto', sans-serif;
  margin: auto;
  padding-top: 80px;
}

.table {
  width: 900px;
  margin: auto;
}

thead {
}

.header1-bg {
  background: #000000;
  color: #ffffff;
}

.header1-bg div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
}

.header2-bg {
  background-color: #353a40;
  color: #ffffff;
}

.tbl-start {
  display: flex;
  justify-items: flex-start;
  margin-left: 60px;
}

/* BUTTONS */
.btn {
  border: 2px solid #800080;
  border-radius: 25px;
  background-color: purple;
  color: white;
  font-weight: bold;
  font-size: 18px;
  height: 55px;
  letter-spacing: 1px;
  transition: all 0.3s ease-out;
}

.btn:active:focus,
.btn:focus,
.btn:hover {
  background-color: #380f38 !important;
  border: none !important;
  outline: none !important;
  color: white;
}

btn:focus,
.edit-btn:focus,
.delete-btn:focus,
.btn-log:focus {
  outline: 0;
  border: none;
}

.edit-btn,
.delete-btn,
.btn-log {
  border: 0;
}

.btn-link {
  text-decoration: none;
  color: #d3d3d3;
}

.btn-link:hover {
  text-decoration: none;
  color: #d3d3d3;
}

.add-btn {
  width: 60%;
  height: 50px;
}

.btn-log {
  font-weight: bold;
  font-size: 14px;
  padding: 0 15px;
  height: 35px;
  letter-spacing: 1px;
}

.fa-edit,
.fa-trash {
  font-size: 18px;
  color: whitesmoke;
}

.fa-edit:hover,
.fa-trash:hover {
  color: purple;
}

/* MODAL */
.modal-overlay {
  background-color: rgba(255, 100, 255, 0.1);
}

.modal-form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  font-family: 'Roboto', sans-serif;
  margin: 0 auto;
}

.modal-form {
  background-color: rgba(0, 0, 0, 0.9);
  border-radius: 8px;
}

.react-datepicker-wrapper,
.react-datepicker__input-container,
.react-datepicker__input-container input {
  background-color: transparent;
  outline: none;
  border: none;
  color: white;
  font-size: 18px;
}

/*SCROLL BAR*/

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: black;
  border-bottom-color: white;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #681e68;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #380f38;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
